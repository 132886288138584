.password-recovery-page {
    width: 100vw;
    min-height: 100vh;
    background-image: linear-gradient(
            rgba(36, 71, 35, 0.7),
            rgba(36, 71, 35, 0.7)
        ),
        /* bottom, image */ url('../../images/tlo_logowanie.webp');
    background-position: center;
    background-size: cover;
    padding-bottom: 50px;
    padding-top: 80px;
}

.password-recovery-page__form {
    width: fit-content;
    max-width: 400px;
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: relative;
    padding: 10px 10px 30px;
    border-radius: 5px;
}

.password-recovery-page__buttons-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.password-recovery-page__closing-button {
    background-color: transparent;
    border: none;
    font-size: 2.8rem;
    color: var(--main-color);
    cursor: pointer;
}

.password-recovery-page__info-button {
    font-size: 1.6rem;
    background-color: transparent;
    color: var(--main-color);
    border: 2px solid var(--secondary-color);
    padding: 3px;
    width: 25px;
    height: 25px;
    border-radius: 8px;
}

.password-recovery-page__form-title {
    font-size: 4rem;
    font-weight: 400;
    padding-bottom: 20px;
    text-transform: uppercase;
    color: var(--secondary-color);
    font-family: 'Teko';
}

.password-recovery-page__form-slogan {
    text-align: center;
    line-height: 120%;
    color: var(--main-color);
    margin-bottom: 30px;
}

.password-recovery-page__input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.password-recovery-page__input-label {
    color: var(--main-color);
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 5px;
}

.password-recovery-page__input {
    font-size: 1.6rem;
    padding: 5px 3px;
    border: none;
    border: 2px solid var(--secondary-color);
    border-radius: 3px;
    max-width: 200px;
    background-color: transparent;
}

.password-recovery-page__input-desc {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 15px;
}

.password-recovery-page__input:focus {
    outline: none;
}

.password-recovery-page__alert {
    color: #f00;
    font-size: 1.6rem;
    font-weight: 600;
}

.password-recovery-page__password-wrapper {
    position: relative;
}

.password-recovery-page__password-icon-button {
    border: none;
    background: transparent;
    font-size: 2rem;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--main-color);
}

.password-recovery-page__info-text-wrapper {
    position: relative;
}

.password-recovery-page__info-button:hover .password-recovery-page__info-text {
    display: block;
}

.password-recovery-page__info-text {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 100%;
    margin-right: 15px;
    background-color: var(--white-color);
    width: 300px;
    padding: 10px 20px;
    border-radius: 5px;
}

.password-recovery-page__info-text {
    font-family: 'Roboto';
    text-transform: none;
    color: #000;
}

.password-recovery-page__span-block {
    display: block;
}
