.mailbox-wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: var(--light-main-color2);
}

.mailbox-conetent__setter {
  width: 400px;
  height: 1px;
}

.mailbox-wrapper__top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}
.mailbox-incluer__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailbox-includer__input {
  width: 300px;
  height: 35px;
  border-radius: 8px 0 0 8px;
  background: var(--lighter-main-color);
  border: none;
  padding: 10px 18px;
}

.mailbox-includer__button {
  width: 50px;
  height: 35px;
  background: var(--main-color);
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
}

.mailbox-table {
  width: 100%;
  padding: 0 30px 30px 30px;
  height: 85vh;
}

.mailbox-table__style {
  background: var(--lighter-main-color);
  width: 100%;
  max-height: 85vh;
  border-radius: 8px;
  background: var(--light-main-color);
  overflow-y: auto;
}

.consultant-mailbox-wrapper {
  display: flex;
  width: 100%;
  max-width: 1100px;
  margin: 10px auto;
  justify-content: center;
}

.consultant-mailbox-button {
  margin: 0 20px;
  background-color: var(--main-color);
  padding: 5px 10px;
  border: none;
  outline: none;
  border-radius: 5px;
}


.status {
  padding: 3px 7px;
  gap: 11px;
  border-radius: 8px;
}

.status-new {
  color: #fff;
  background: #fe6a2a;
}
.status-completed {
  color: var(--main-color);
  background: #a0df9f;
}
.status-new_in_conversation {
  background: #fccd43;
  color: var(--main-color);
}

.status-seen {
  background: #afafaf;
  color: #ffffff;
}

.mailbox-table table {
  width: 100%;
  border-collapse: collapse;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--main-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--main-color);
}

::-webkit-scrollbar-thumb:active {
  background: var(--main-color);
}

.mailbox-table tbody {
  overflow: auto;
  width: 100%;
}

.mailbox-table thead tr {
  width: 100%;
  text-align: left !important;
}
.mailbox-table th {
  text-align: left !important;
}

.mailbox-table table tbody tr {
  color: var(--main-color);
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: var(--lighter-main-color);
  border-bottom: 1px solid var(--light-main-color) !important;
}

.mailbox-table tbody tr:hover {
  border-bottom: 1px solid var(--light-main-color);
  background: var(--light-main-color);
  color: white;
}

.mailbox-table td {
  cursor: pointer;
  white-space: nowrap; /* Zapobiega zawijaniu tekstu */
  overflow: hidden; /* Ukrywa tekst poza obszarem komórki */
  text-overflow: ellipsis;
}

.mailbox-table th,
.mailbox-table td {
  padding: 10px 20px;
}

.mailbox-table th {
  text-align: left;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mailbox-table .mailbox-thead {
  border: 1px solid var(--light-main-color2);
  background: var(--main-color);
}

.mailbox-wrapper__box__element input:hover,
.mailbox-wrapper__box__element input:focus {
  outline: none;
}

.mailbox-button__back {
  width: 200px;
}

.mailbox-title {
  text-align: center;

  color: var(--main-color);
  height: fit-content;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px; /* 312.5% */
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.mailbox-wrapper__box__element {
  width: 100%;
  padding: 0 30px 30px 30px;
  height: 85vh;
  border-radius: 8px;
}

.mailbox-mail__end {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 200px;
  justify-content: right;
}

.mailbox-mail__topic {
  color: var(--main-color);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mailbox-mail__text {
  color: var(--main-color);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mailbox-wrapper__box__element input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--main-color); /* Brązowy kolor obwódki */
  background-color: #fff; /* Białe tło */
  outline: none;
  cursor: pointer;
}

/* Ukrywanie oryginalnego znacznika (checkmark) */
.mailbox-wrapper__box__element input[type="checkbox"]::before {
  content: "\2713"; /* Kod znaku checkmark */
  display: none; /* Ukrywamy oryginalny checkmark */
  text-align: center;
  font-size: 14px;
  color: var(--main-color); /* Brązowy kolor checkmark */
  line-height: 16px;
}

/* Styl dla zaznaczonego checkboxa */
.mailbox-wrapper__box__element input[type="checkbox"]:checked {
  background-color: #fff; /* Białe tło (można zostawić bez zmian) */
  border: 2px solid var(--main-color); /* Brązowy kolor obwódki (można zostawić bez zmian) */
}

/* Styl dla zaznaczonego checkboxa - pokazujemy customowy checkmark */
.mailbox-wrapper__box__element input[type="checkbox"]:checked::before {
  display: block;
}

.mailbox-message__element {
  width: 70vw;
}

.mailbox-date {
  width: 5vw;
}

.mailbox-sender {
  width: 10vw;
}

.mailbox-wrapper__elements {
  height: 50vh;
  overflow: auto;
}

.mailbox-wrapper__typing {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: var(--lighter-main-color);
}

.mailbox-texarea__typing {
  resize: none;
  width: 100%;
  border: none;
  border-radius: 0px 8px 0px 8px;
  padding: 30px;
  color: var(--main-color);
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mailbox-texarea__typing:focus {
  outline: none;
}

.mailbox-button__typing {
  padding: 10px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border-radius: 8px;
  background: var(--main-color);
  border: none;
  cursor: pointer;
  height: fit-content;
  margin-right: 30px;

  color: #ed9823;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.mailbox-icon {
  width: 5vw;
}

.mailbox-element {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 30px;
  padding: 5px 0;
  border-bottom: 1px solid var(--light-main-color2);

  color: var(--main-color);
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mailbox-button__back {
  cursor: pointer;
}

.mailbox-wrapper__box__element__style {
  width: 100%;
  height: 80vh;
  background: var(--lighter-main-color);
  border-radius: 8px;
  position: relative;
}

.mailbox-wrapper__top_box {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}

.mailbox-message__image-wrapper {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.mailbox-message__image {
  max-width: 500px;
  max-height: 500px;
  height: auto;
  width: auto;
}

.mailbox-message__word {
  padding: 10px;
  border: 1px solid #555;
  width: fit-content;
  margin: 10px auto;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.7);
}