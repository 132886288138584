.home-page {
    padding-top: 120px;
    background-image:  linear-gradient(
        rgba(36, 71, 35, 0.7), 
        rgba(36, 71, 35, 0.7)
      ),
      /* bottom, image */
      url("../../images/tlo_logowanie.webp");;
      background-position: center;
      background-size: cover;
      width: 100vv;
      min-height: 100vh;
}

.home-page__content-wrapper {
  min-height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-page__main-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.home-page__slogan {
  font-size: 6rem;
  max-width: 40vw;
  text-align: center;
  color: var(--white-color);
  font-family: 'Teko';
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 110%;
  margin: 0 20px;
}

.home-page__login-register-wrapper {
  display: flex;
  height: 450px;
  align-items: flex-start;
}

.home-page__login-div {
  align-self: flex-end;
  margin-right: 10px;
}

.home-page__register-div {
  margin-right: 40px;
}

.home-page__login-div-header, .home-page__register-div-header {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Teko';
  font-size: 4rem;
  letter-spacing: 1px;
}

.home-page__login-div-header {
  color: var(--secondary-color);
  font-family: 'Roboto';
}

.home-page__register-div-slogan, .home-page__login-div-slogan {
  margin: 15px 0 30px;
}

.home-page__login-link, .home-page__register-link {
  text-decoration: none;
}

.home-page__more-info-div {
  margin: 0 10px 20px 40px;
  max-width: none;
  width: fit-content;
}

.home-page__more-info-header {
  font-family: 'Teko';
  font-size: 4rem;
}

.home-page__more-info-button {
  background-color: transparent;
  border: none;
  font-family: 'Teko';
  font-size: 2rem;
  margin-top: 5px;
  color: var(--secondary-color);
  display: block;
  margin-left: auto;
  cursor: pointer;
}

@media (max-width: 1105px) {
  .home-page__main-content {
    flex-direction: column;
    margin: 50px 0;
  }
  
  .home-page__slogan  {
    margin: 0 10px 20px;
    font-size: 5rem;
    max-width: none;

  }

  .home-page__login-register-wrapper {
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .home-page__login-div {
    align-self: center;
    margin: 0 40px 20px 20px;
    width: auto;
  }
  
  .home-page__register-div {
    margin: 0 40px 20px 20px;
    width: auto;
  }
  
  .home-page__more-info-div {
  margin: 0 10px 30px 40px;
    width: auto;
  }

}